export interface ErrorBoxProps {
  text?: string;
}

const ErrorBox: React.FC<ErrorBoxProps> = ({ text }) => {
  return (
    <div className="flex flex-col items-center justify-center py-16 bg-red-100">
      <p className="bg-red-600 text-white font-semibold py-2 px-4 rounded-lg">{text}</p>
    </div>
  );
};

export default ErrorBox;
