import { ApolloError } from "@apollo/client";
import { Fragment, ReactNode } from "react";
import ErrorBox from "./ErrorBox";
import Loading from "./Loading";

export interface PageLoaderProps {
  children: ReactNode;
  loading: boolean;
  error?: ApolloError | string;
  showError?: boolean;
}

const PageLoader: React.FC<PageLoaderProps> = ({
  children,
  loading,
  error,
  showError = true,
}) => {
  if (loading) {
    return <Loading />;
  }

  if (error && showError) {
    return (
      <ErrorBox text={typeof error === "string" ? error : error.message} />
    );
  }

  return <Fragment>{children}</Fragment>;
};

export default PageLoader;
