export type Raqam = {
  value: string;
  toEnglish: () => number | string;
};

export type RaqamOptions = {
  comma?: boolean;
  dash?: boolean;
  isNumber?: boolean;
};

const raqams: Array<string> = [
  "۰",
  "۱",
  "۲",
  "۳",
  "۴",
  "۵",
  "۶",
  "۷",
  "۸",
  "۹",
];

const commaSeparatedNumber = (string: string) => {
  const regex = /(\d+)(\d{3})/;
  while (regex.test(string)) {
    string = string.replace(regex, "$1,$2");
  }
  return string;
};

export const toPersian = (value: number | string, options?: RaqamOptions) => {
  let stringNumber = `${value}`;
  if (options && options.comma) {
    stringNumber = commaSeparatedNumber(stringNumber);
  }
  const result: string = stringNumber.replace(/\d/g, (match) => {
    return raqams[parseInt(match)] ? raqams[parseInt(match)] : match;
  });
  return result;
};

const raqam = (number: number | string, options: RaqamOptions = {}): Raqam => {
  const value = typeof number === "string" ? raqamParser(number) : number;

  return {
    value: toPersian(value, options),
    toEnglish: () => {
      return value;
    },
  };
};

export const raqamParser = (value: string, isNumber?: boolean) => {
  const cleanString: string[] = value.replace(/[\s,-]/g, "").split("");
  const englishArray: (
    | string
    | number
  )[] = cleanString.map((character: string) =>
    raqams.indexOf(character) > -1 ? raqams.indexOf(character) : character
  );
  const englishString = englishArray.join("");
  return isNumber ? Number(englishString) : englishString;
};

export default raqam;
