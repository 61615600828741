import { Switch } from "react-router";
import TicketScreen from "screens/ticket";
import TicketsScreen from "screens/tickets";
import UserScreen from "screens/user";
import SleepSchoolUsersScreen from "screens/users/sleepschool";
import ProtectedRoute from "./ProtectedRoute";
import Redirector from "./Redirector";

export interface NavigatorProps {}

const Navigator: React.FC<NavigatorProps> = () => {
  return (
    <Switch>
      <ProtectedRoute path="/tickets" component={TicketsScreen} />
      <ProtectedRoute
        path="/users/sleepschool"
        component={SleepSchoolUsersScreen}
      />
      <ProtectedRoute path="/user/:id" component={UserScreen} />
      <ProtectedRoute path="/ticket/:id" component={TicketScreen} />
      <ProtectedRoute component={Redirector} />
    </Switch>
  );
};

export default Navigator;
