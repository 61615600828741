import { atom } from "recoil";

export const authState = atom<boolean | undefined>({
  key: "auth",
  default: undefined,
});

export const tokenState = atom<string | undefined>({
  key: "tokenState",
  default: undefined,
});
