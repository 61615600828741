import { Route, RouteProps } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { authState } from "recoil/atoms/auth";

export interface ProtectedRouteProps extends RouteProps {}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  component,
  ...rest
}) => {
  const isAuthenticated = useRecoilValue(authState);

  if (isAuthenticated && component) {
    return <Route {...rest} component={component} />;
  }

  /*
  TODO:Access Denied Page
  */
  return <div>Access Denied</div>;
};

export default ProtectedRoute;
