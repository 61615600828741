import Pane from "components/layout/Pane";
import { ITicketResponse } from "models/Ticket";
import { toPersian } from "utils/raqam";
import ReactHtmlParser from "react-html-parser";
import { FaRegCheckCircle } from "react-icons/fa";
import { parseDateTime } from "utils/dateParser";

export interface ResponseItemProps extends ITicketResponse {}

const ResponseItem: React.FC<ResponseItemProps> = ({ user, body, date }) => {
  return (
    <Pane>
      <div className="flex justify-between items-center pb-3 border-b border-gray-200">
        <span className="text-gray-700 font-semibold">
          <FaRegCheckCircle className="inline-block text-green-600 text-3xl ml-2" />
          {toPersian(user)}
        </span>
        <span className="text-gray-600 text-sm">{toPersian(parseDateTime(date))}</span>
      </div>
      <div className="text-sm pt-3 text-justify">
        {ReactHtmlParser(toPersian(body))}
      </div>
    </Pane>
  );
};

export default ResponseItem;
