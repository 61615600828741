import { useLazyQuery } from "@apollo/client";
import PageLoader from "components/app/utils/PageLoader";
import Ticket from "components/ticket";
import { GET_SINGLE_TICKET } from "graphql/queries/ticket";
import { IDreamlabData } from "models/DreamlabData";
import { ITicket, ITicketResponse } from "models/Ticket";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { dreamlabDataParser } from "utils/dreamlabDataParser";
import { getQueryStringValue } from "utils/queryString";
import { parseStoreData } from "utils/storeParser";
import { fullTicketPareser } from "utils/ticketParser";

const TicketScreen: React.FC = () => {
  const [ticket, setTicket] = useState<ITicket | undefined>(undefined);
  const [dreamlabData, setDreamlabData] = useState<IDreamlabData>({});
  const { id } = useParams<any>();

  const infantID = Number(getQueryStringValue("infant"));

  const [getInfo, { loading, error }] = useLazyQuery(
    GET_SINGLE_TICKET(!!infantID),
    {
      fetchPolicy: "network-only",
      onCompleted: (data) => {
        const parsedTicket = fullTicketPareser(data.SUPPORT_ticket);
        const parsedStoreData = parseStoreData(data);
        const parsedDreamlabData = dreamlabDataParser(
          data.SUPPORT_ticket,
          parsedStoreData
        );
        setTicket(parsedTicket);
        setDreamlabData(parsedDreamlabData);
      },
    }
  );

  useEffect(() => {
    const keys = ["601", "603", "108", "appState", "weight"];
    getInfo({
      variables: {
        infant_id: infantID,
        ticket_id: Number(id),
        keys,
      },
    });
  }, [getInfo, id, infantID]);

  const addResponseToTicket = (item: ITicketResponse) => {
    const modifiedResponses: ITicketResponse[] = ticket?.responses
      ? [...ticket.responses, item]
      : [item];
    const modifiedTicket: ITicket | undefined = ticket
      ? { ...ticket, responses: modifiedResponses }
      : undefined;
    setTicket(modifiedTicket);
  };

  return (
    <PageLoader loading={loading} error={error}>
      {ticket && (
        <Ticket
          dreamlabData={dreamlabData}
          ticketData={ticket}
          addResponseToTicket={addResponseToTicket}
        />
      )}
    </PageLoader>
  );
};

export default TicketScreen;
