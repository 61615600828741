import { appStrings } from "data/app/appStrings";

export interface CancelButtonProps {
  onClose: () => void;
}

const CancelButton: React.FC<CancelButtonProps> = ({ onClose }) => {
  return (
    <button
      className="bg-gray-200 py-1 px-2 rounded-lg outline-none focus:outline-none"
      onClick={onClose}
    >
      {appStrings.cancel}
    </button>
  );
};

export default CancelButton;
