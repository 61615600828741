import { menuItems } from "data/layout/menu";
import MenuItem from "./MenuItem";
import { FaUser } from "react-icons/fa";
import { appStrings, brand } from "data/app/appStrings";
import logo from "images/logo.svg";

export interface MenuProps {}

const Menu: React.FC<MenuProps> = () => {
  return (
    <div className="flex flex-col h-full bg-gradient-to-b from-indigo-900 to-blue-500 pt-4 px-4">
      <div className="flex flex-row items-center justify-center text-white">
        <img src={logo} className="w-6 ml-3" alt="logo" />
        <span className="text-xl font-semibold">{brand}</span>
      </div>
      <div className="w-10/12 h-1 mx-auto bg-white bg-opacity-20 rounded-xl my-6"></div>
      <div className="flex flex-col space-y-3">
        {menuItems.map((item) => (
          <MenuItem key={item.url} {...item} />
        ))}
        <a
          href={process.env.REACT_APP_ACCOUNT_MANAGER}
          className="text-white bg-white bg-opacity-10 py-2 px-3 rounded-xl text-center transition duration-300 hover:bg-opacity-25"
        >
          <FaUser className="inline-block ml-2" />
          <span>{appStrings.profile}</span>
        </a>
      </div>
    </div>
  );
};

export default Menu;
