import { IDreamlabData } from "models/DreamlabData";
import { valueGetter } from "./storeParser";

export const dreamlabDataParser = (ticketObject: any, dataObject: any) => {
  const parsedWeight = valueGetter(dataObject, "weight")
    ? JSON.parse(valueGetter(dataObject, "weight") as string)
    : {};
  const calculatedWeight =
    Number(parsedWeight.weightKilo) + Number(parsedWeight.weightGeram) / 1000;
  const hasFeeding = valueGetter(dataObject, "601", true);
  const parsedState = valueGetter(dataObject, "appState")
    ? JSON.parse(valueGetter(dataObject, "appState") as string)
    : {};
  const result: IDreamlabData = {
    infant: {
      name: ticketObject?.infant?.name,
      age: ticketObject?.infant?.adjusted_age,
      weight: calculatedWeight,
      usedFrom: ticketObject?.infant?.dreamlab_used_from,
    },
    feeding: {
      has: !!hasFeeding,
      type: valueGetter(dataObject, "108")?.toString(),
      count: hasFeeding ? Number(valueGetter(dataObject, "603")) : 0,
    },
    method: parsedState.method,
  };
  return result;
};
