import { gql } from "@apollo/client";

export const ME = gql`
  query me {
    me {
      first_name
      last_name
    }
  }
`;

export const IMPERSONATE = gql`
  mutation AUTH_impersonate($user_id: Int!) {
    AUTH_impersonate(user_id: $user_id)
  }
`;

export const LOGOUT = gql`
  mutation logout {
    logout
  }
`;
