import Pane from "components/layout/Pane";
import { TH, THead } from "components/utils/TableUtils";
import { appStrings } from "data/app/appStrings";
import { IUser } from "models/User";
import UserItem from "./item";

export interface UserListProps {
  users: IUser[];
  hasMore: boolean;
  loadMore: () => void;
}

const UserList: React.FC<UserListProps> = ({ users, hasMore, loadMore }) => {
  return (
    <Pane>
      <table className="w-full">
        <THead>
          <tr>
            <TH>{appStrings.id}</TH>
            <TH>{appStrings.userPersianName}</TH>
            <TH>{appStrings.phone}</TH>
            <TH>{appStrings.infantName}</TH>
            <TH>{appStrings.actions}</TH>
          </tr>
        </THead>
        <tbody>
          {users.map((item, index) => (
            <UserItem key={item.id} index={index} {...item} />
          ))}
        </tbody>
      </table>
      {hasMore && (
        <div className="h-1 bg-gray-300 rounded-lg flex items-center justify-center my-6">
          <button onClick={loadMore} className="bg-white px-6 text-sm">
            {appStrings.loadMore}
          </button>
        </div>
      )}
    </Pane>
  );
};

export default UserList;
