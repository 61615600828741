import { IServerData } from "models/ServerData";
import { boolParser } from "./booleanParser";

export const parseStoreData = (data: any): IServerData[] => {
  return data.DREAMLAB_get_infant_data;
};

export const parseSentData = (data: any): IServerData[] => {
  return data.DREAMLAB_get_infant_data;
};

export const valueGetter = (
  data: IServerData[],
  key: string,
  isBool?: boolean
) => {
  const value = data ? data.find((item) => item.key === key)?.value : "";
  if (value !== undefined) {
    return isBool ? boolParser(value) : value;
  }
  return undefined;
};
