import { IDreamlabData } from "models/DreamlabData";
import { ITicket, ITicketResponse } from "models/Ticket";
import ResponseScreen from "screens/ticket/ResponseScreen";
import DreamlabInfo from "./DreamlabInfo";
import InfantInfo from "./InfantInfo";
import ResponseItem from "./ResponseItem";
import TicketInfo from "./TicketInfo";

export interface TicketProps {
  ticketData: ITicket;
  dreamlabData: IDreamlabData;
  addResponseToTicket: (item: ITicketResponse) => void;
}

const Ticket: React.FC<TicketProps> = ({
  ticketData,
  dreamlabData,
  addResponseToTicket,
}) => {
  return (
    <div>
      <div className="grid grid-cols-2 gap-6">
        <InfantInfo
          name={dreamlabData.infant?.name}
          usedFrom={dreamlabData.infant?.usedFrom}
          weight={dreamlabData.infant?.weight}
          age={dreamlabData.infant?.age}
        />
        <DreamlabInfo
          count={dreamlabData.feeding?.count}
          selectedMethod={dreamlabData.method}
          type={dreamlabData.feeding?.type}
          has={dreamlabData.feeding?.has}
        />
      </div>
      <div>
        <TicketInfo
          name={ticketData.user}
          mobile={ticketData.mobile}
          title={ticketData.title}
          body={ticketData.body}
          date={ticketData.date}
        />
      </div>
      <div>
        {ticketData.responses.map((item) => (
          <ResponseItem key={item.id} {...item} />
        ))}
      </div>
      <div>
        <ResponseScreen
          userID={ticketData.userID}
          addResponseToTicket={addResponseToTicket}
        />
      </div>
    </div>
  );
};

export default Ticket;
