import cubeLoading from "images/cubeLoading.gif";

export interface LoadingProps {}

const Loading: React.FC<LoadingProps> = () => {
  return (
    <div className="flex flex-col h-screen items-center justify-center">
      <img src={cubeLoading} alt="..." className="w-44" />
    </div>
  );
};

export default Loading;
