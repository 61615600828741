import AnswerBadge from "components/utils/AnswerBadge";
import { TD } from "components/utils/TableUtils";
import TicketStatusBadge from "components/utils/TicketStatusBadge";
import { appStrings } from "data/app/appStrings";
import { ITicketSummary } from "models/Ticket";
import { NavLink } from "react-router-dom";
import { toPersian } from "utils/raqam";

export interface TicketItemProps extends ITicketSummary {
  index: number;
}

const TicketItem: React.FC<TicketItemProps> = ({
  id,
  infantID,
  title,
  user,
  mobile,
  isAnswered,
  isClosed,
  index,
}) => {
  const rowBG = index % 2 === 0 ? "bg-white" : "bg-blue-50";

  return (
    <tr className={`${rowBG}`}>
      <TD>{toPersian(id)}</TD>
      <TD>{toPersian(user)}</TD>
      <TD>{toPersian(mobile)}</TD>
      <TD>{toPersian(title.substring(0, 50))}...</TD>
      <TD>
        <AnswerBadge isAnswered={isAnswered} />
      </TD>
      <TD>
        <TicketStatusBadge isClosed={isClosed} />
      </TD>
      <TD>
        <NavLink
          to={`/ticket/${id}?infant=${infantID}`}
          className="text-pink-700 border-b border-pink-700 text-xs"
        >
          {appStrings.detail}
        </NavLink>
      </TD>
    </tr>
  );
};

export default TicketItem;
