import { cloneElement, ReactElement } from "react";

export interface ButtonProps {
  label: string;
  icon?: ReactElement;
  disabled?: boolean;
  className?: string;
  onClick: () => void;
}

const Button: React.FC<ButtonProps> = ({
  label,
  icon,
  disabled = false,
  className = "",
  onClick,
}) => {
  const clonedIcon = icon
    ? cloneElement(icon, { className: "inline-block ml-2" })
    : undefined;
  return (
    <button
      disabled={disabled}
      className={`py-1 px-2 rounded-lg outline-none focus:outline-none ${className}`}
      onClick={onClick}
    >
      {clonedIcon}
      {label}
    </button>
  );
};

export default Button;
