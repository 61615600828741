import loadingSRC from "images/loading.svg";

export interface SubmitButtonProps {
  label: string;
  loading: boolean;
  type?: "button" | "submit" | "reset" | undefined;
  onSubmit?: () => void;
}

const SubmitButton: React.FC<SubmitButtonProps> = ({
  label,
  type = "button",
  loading,
  onSubmit,
}) => {
  return (
    <button
      disabled={loading}
      type={type}
      className="bg-green-600 font-semibold text-white py-1 px-4 rounded-lg outline-none focus:outline-none"
      onClick={onSubmit}
    >
      {label}
      {loading && <img src={loadingSRC} alt="..." className="inline-block w-6 mr-2" />}
    </button>
  );
};

export default SubmitButton;
