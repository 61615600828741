import { ITicket, ITicketResponse, ITicketSummary } from "models/Ticket";

export const ticketParser = (serverTicket: any) => {
  const isAnswered = serverTicket.response_count > 0;
  const mobile = serverTicket.user.mobile.substring(3)
  const user =
    serverTicket.user.first_name || serverTicket.user.last_name
      ? `${serverTicket.user.first_name} ${serverTicket.user.last_name}`
      : "-";
  const parsedTicket: ITicketSummary = {
    id: Number(serverTicket.id),
    infantID: Number(serverTicket?.infant?.id),
    title: serverTicket.title,
    user,
    mobile,
    isAnswered,
    isClosed: serverTicket.closed,
  };
  return parsedTicket;
};

export const ticketResponseParser = (item: any) => {
  const user =
    item.user.first_name || item.user.last_name
      ? `${item.user.first_name} ${item.user.last_name}`
      : item.user.mobile.substring(3);
  const date = item.created_at;
  return {
    id: Number(item.id),
    body: item.body,
    user,
    date,
  };
};

export const fullTicketPareser = (serverTicket: any) => {
  const parsedTicket = ticketParser(serverTicket);
  const responses: ITicketResponse[] = serverTicket.responses.map((item: any) =>
    ticketResponseParser(item)
  );
  const result: ITicket = {
    ...parsedTicket,
    userID: Number(serverTicket.user.id),
    mobile: `0${serverTicket.user.mobile.substring(3)}`,
    body: serverTicket.body,
    date: serverTicket.created_at,
    responses,
  };
  return result;
};

export const ticketArrayParser = (serverTickets: any[]) => {
  const parsedTickets: ITicketSummary[] = serverTickets.map((item) =>
    ticketParser(item)
  );
  return parsedTickets;
};
