import { ReactNode } from "react";

export interface PaneProps {
  children: ReactNode;
}

const Pane: React.FC<PaneProps> = ({ children }) => {
  return <div className="bg-white py-4 px-4 rounded-lg mb-4">{children}</div>;
};

export default Pane;
