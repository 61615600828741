import { IUser } from "models/User";
import { atom } from "recoil";

/*
TODO:Change user default state after fetching auth apis
*/

export const userState = atom<IUser>({
  key: "userState",
  default: {
    firstName: "حسین",
    lastName: "احمدی",
  },
});
