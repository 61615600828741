import { ReactNode } from "react";
import Menu from "./Menu";

export interface AppWrapperProps {
  children: ReactNode;
}

const AppWrapper: React.FC<AppWrapperProps> = ({ children }) => {
  return (
    <div className="flex flex-row h-screen max-h-screen">
      <div className="w-2/12">
        <Menu />
      </div>
      <div className="w-10/12 max-h-full overflow-y-auto bg-gray-200 px-4 pt-4">
        {children}
      </div>
    </div>
  );
};

export default AppWrapper;
