import Pane from "components/layout/Pane";
import { TH, THead } from "components/utils/TableUtils";
import { appStrings } from "data/app/appStrings";
import { ITicketSummary } from "models/Ticket";
import TicketItem from "./TicketItem";

export interface TicketListProps {
  tickets: ITicketSummary[];
  hasMore: boolean;
  loadMore: () => void;
}

const TicketList: React.FC<TicketListProps> = ({
  tickets,
  hasMore,
  loadMore,
}) => {
  return (
    <Pane>
      <table className="w-full">
        <THead>
          <tr>
            <TH>{appStrings.id}</TH>
            <TH>{appStrings.userPersianName}</TH>
            <TH>{appStrings.mobile}</TH>
            <TH>{appStrings.ticketTitle}</TH>
            <TH>{appStrings.ticketAnswerStatus}</TH>
            <TH>{appStrings.ticketStatus}</TH>
            <TH>{appStrings.actions}</TH>
          </tr>
        </THead>
        <tbody>
          {tickets.map((item, index) => (
            <TicketItem key={item.id} index={index} {...item} />
          ))}
        </tbody>
      </table>
      {hasMore && (
        <div className="h-1 bg-gray-300 rounded-lg flex items-center justify-center my-6">
          <button onClick={loadMore} className="bg-white px-6 text-sm" >{appStrings.loadMore}</button>
        </div>
      )}
    </Pane>
  );
};

export default TicketList;
