import Pane from "components/layout/Pane";
import { TD } from "components/utils/TableUtils";
import { appStrings } from "data/app/appStrings";
import { IDreamlabInfant } from "models/DreamlabData";
import { toPersian } from "utils/raqam";
import { differenceInDays, parseISO } from "date-fns-jalali";

export interface InfantInfoProps extends IDreamlabInfant {}

const InfantInfo: React.FC<InfantInfoProps> = ({
  name,
  age,
  weight,
  usedFrom,
}) => {
  return (
    <Pane>
      <table className="w-full">
        <tbody>
          <tr>
            <TD>{appStrings.infantName}</TD>
            <TD>{name}</TD>
          </tr>
          <tr className="bg-blue-100">
            <TD>{appStrings.age}</TD>
            <TD>
              {toPersian(age || 0)} {appStrings.monthsOld}
            </TD>
          </tr>
          <tr>
            <TD>{appStrings.weight}</TD>
            <TD>
              {toPersian(weight || 0)} {appStrings.kilogeram}
            </TD>
          </tr>
          <tr className="bg-blue-100">
            <TD>{appStrings.usedDays}</TD>
            <TD>
              {toPersian(
                differenceInDays(new Date(), parseISO(usedFrom || ""))
              )}
            </TD>
          </tr>
        </tbody>
      </table>
    </Pane>
  );
};

export default InfantInfo;
