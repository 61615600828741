import { TD } from "components/utils/TableUtils";
import { appStrings } from "data/app/appStrings";
import { IUser } from "models/User";
import { NavLink } from "react-router-dom";
import { userNameParser } from "utils/functions";
import { toPersian } from "utils/raqam";

export interface UserItemProps extends IUser {
  index: number;
}

const UserItem: React.FC<UserItemProps> = ({
  id,
  firstName,
  lastName,
  index,
  phone,
  infantID,
  infantName,
}) => {
  const name = userNameParser(firstName, lastName, "-");

  const rowBG = index % 2 === 0 ? "bg-white" : "bg-blue-50";

  return (
    <tr className={`${rowBG}`}>
      <TD>{toPersian(id || 0)}</TD>
      <TD>{toPersian(name)}</TD>
      <TD>{toPersian(phone?.substring(1) || "")}</TD>
      <TD>{toPersian(infantName || "")}</TD>
      <TD>
        <NavLink
          to={`/user/${id}?infant=${infantID}`}
          className="text-pink-700 border-b border-pink-700 text-xs"
        >
          {appStrings.detail}
        </NavLink>
      </TD>
    </tr>
  );
};

export default UserItem;
