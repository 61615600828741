import gql from "graphql-tag";

export const GET_TICKETS = gql`
  query SUPPORT_tickets($closed: Boolean, $category: String, $page: Int!) {
    SUPPORT_tickets(closed: $closed, category: $category, page: $page) {
      paginator_meta {
        current_page
        last_page
      }
      data {
        id
        title
        user {
          first_name
          last_name
          mobile
        }
        closed
        response_count
        infant {
          id
        }
      }
    }
  }
`;

export const GET_SINGLE_TICKET = (hasInfant: boolean) => gql`
  query SUPPORT_ticket($ticket_id: Int!${
    hasInfant ? `, $keys: [String]!, $infant_id: Int!` : ""
  }) {
    ${
      hasInfant
        ? `DREAMLAB_get_infant_data(keys: $keys, infant_id: $infant_id) {
      key
      value
    }`
        : ""
    }
    SUPPORT_ticket(ticket_id: $ticket_id) {
      id
      title
      body
      created_at
      user {
        id
        first_name
        last_name
        mobile
      }
      closed
      infant {
        id
        name
        adjusted_age
        dreamlab_used_from
      }
      responses {
        id
        created_at
        user {
          first_name
          last_name
          mobile
        }
        body
      }
    }
  }
`;

export const RESPOND_TICKET = gql`
  mutation SUPPORT_respond_ticket($input: SUPPORT_ticketResponsInput) {
    SUPPORT_respond_ticket(input: $input) {
      id
      created_at
      user {
        first_name
        last_name
        mobile
      }
      body
    }
  }
`;
