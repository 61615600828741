import { appStrings } from "data/app/appStrings";
import { FaInfoCircle } from "react-icons/fa";
import Modal from "react-modal";
import CancelButton from "./CancelButton";
import SubmitButton from "./SubmitButton";

export type IAlertBodyText = {
  title: string;
  description: string;
};

export interface ConfirmationModalProps {
  open: boolean;
  bodyTexts: IAlertBodyText;
  loading: boolean;
  onConfirm: () => void;
  onClose: () => void;
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  open,
  bodyTexts,
  loading,
  onConfirm,
  onClose,
}) => {
  return (
    <Modal
      isOpen={open}
      className="w-1/2 mx-auto bg-white mt-64 py-6 px-8 rounded-lg z-50"
      overlayClassName="bg-gray-800 bg-opacity-60 z-40 fixed inset-0"
      onRequestClose={onClose}
    >
      <div className="pb-5 border-b border-gray-200">
        <p className="font-semibold text-gray-700 text-lg">
          <FaInfoCircle className="inline-block ml-3 text-yellow-400 text-3xl" />
          {bodyTexts.title}
        </p>
      </div>
      <div className="py-5 border-b border-gray-200">
        <p>{bodyTexts.description}</p>
      </div>
      <div className="flex justify-end pt-5">
        <div className="ml-3">
          <CancelButton onClose={onClose} />
        </div>
        <SubmitButton
          label={appStrings.accept}
          loading={loading}
          onSubmit={onConfirm}
        />
      </div>
    </Modal>
  );
};

export default ConfirmationModal;
