import Pane from "components/layout/Pane";
import Button from "components/utils/Button";
import { TD } from "components/utils/TableUtils";
import { appStrings } from "data/app/appStrings";
import { IUser } from "models/User";
import { FaSignInAlt } from "react-icons/fa";
import { parseDateTime } from "utils/dateParser";
import { userNameParser } from "utils/functions";
import { toPersian } from "utils/raqam";

export interface UserInfoProps extends IUser {
  impersonate: () => void;
}

const UserInfo: React.FC<UserInfoProps> = ({
  firstName,
  lastName,
  phone,
  registerDate,
  impersonate,
}) => {
  return (
    <Pane>
      <table className="w-full">
        <tbody>
          <tr>
            <TD>{appStrings.userPersianName}</TD>
            <TD>{userNameParser(firstName, lastName, "-")}</TD>
          </tr>
          <tr className="bg-blue-100">
            <TD>{appStrings.phone}</TD>
            <TD>{toPersian(phone?.substring(1) || "")}</TD>
          </tr>
          <tr>
            <TD>{appStrings.registerDate}</TD>
            <TD>{toPersian(parseDateTime(registerDate || ""))}</TD>
          </tr>
          <tr className="bg-blue-100">
            <TD>{appStrings.impersonate}</TD>
            <TD>
              <Button
                label={appStrings.impersonate}
                icon={<FaSignInAlt />}
                onClick={impersonate}
                className="bg-pink-600 text-white"
              />
            </TD>
          </tr>
        </tbody>
      </table>
    </Pane>
  );
};

export default UserInfo;
