export const THead: React.FC = ({ children }) => {
  return (
    <thead className="bg-blue-600 text-white font-semibold">{children}</thead>
  );
};

export const TH: React.FC = ({ children }) => {
  return <th className="py-2 text-sm text-center">{children}</th>;
};

export const TD: React.FC = ({ children }) => {
  return <td className="py-2 text-sm text-center">{children}</td>;
};
