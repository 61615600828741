import { IMenuItem } from "models/MenuItem";
import { FaInbox, FaBed } from "react-icons/fa";

export const menuItems: IMenuItem[] = [
  {
    title: "سوال ها",
    url: "/tickets",
    isExact: false,
    icon: <FaInbox />,
  },
  {
    title: "کاربران مدرسه خواب",
    url: "/users/sleepschool",
    isExact: false,
    icon: <FaBed />,
  },
];
