import { FaCheckCircle, FaInfoCircle } from "react-icons/fa";

export interface AnswerBadgeProps {
  isAnswered: boolean;
}

const AnswerBadge: React.FC<AnswerBadgeProps> = ({ isAnswered }) => {
  if (isAnswered) return <FaCheckCircle className="text-green-600 inline-block" />;
  return <FaInfoCircle className="text-yellow-500 inline-block" />;
};

export default AnswerBadge;
