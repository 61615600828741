import { appStrings } from "data/app/appStrings";

export interface TicketStatusBadgeProps {
  isClosed: boolean;
}

const TicketStatusBadge: React.FC<TicketStatusBadgeProps> = ({ isClosed }) => {
  if (isClosed)
    return (
      <span className="w-8 inline-block text-xs py-1 rounded bg-red-500 text-white">
        {appStrings.closed}
      </span>
    );
  return (
    <span className="w-8 inline-block text-xs py-1 rounded bg-blue-600 text-white">
      {appStrings.open}
    </span>
  );
};

export default TicketStatusBadge;
