import { useLazyQuery } from "@apollo/client";
import PageLoader from "components/app/utils/PageLoader";
import TicketList from "components/tickets";
import { GET_TICKETS } from "graphql/queries/ticket";
import { ITicketSummary } from "models/Ticket";
import { useEffect, useState } from "react";
import { ticketArrayParser } from "utils/ticketParser";

const TicketsScreen: React.FC = () => {
  const [tickets, setTickets] = useState<ITicketSummary[]>([]);
  const [currPage, setCurrPage] = useState(1);
  const [lastPage, setLastPage] = useState(0);

  const [getTickets, { loading, error }] = useLazyQuery(GET_TICKETS, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      const serverTickets = data.SUPPORT_tickets.data;
      const pageMeta = data.SUPPORT_tickets.paginator_meta;
      const parsedTickets = ticketArrayParser(serverTickets);
      setTickets([...tickets, ...parsedTickets]);
      setLastPage(pageMeta.last_page);
    },
  });

  useEffect(() => {
    getTickets({
      variables: {
        page: currPage,
      },
    });
  }, [getTickets, currPage]);

  const loadMore = () => {
    setCurrPage(currPage + 1);
  };

  return (
    <PageLoader loading={loading} error={error}>
      <TicketList
        tickets={tickets}
        hasMore={lastPage !== currPage}
        loadMore={loadMore}
      />
    </PageLoader>
  );
};

export default TicketsScreen;
