import ResponseForm from "components/ticket/ResponseForm";
import RichTextEditor, { EditorValue } from "react-rte";
import { useState } from "react";
import { ITicketResponse } from "models/Ticket";
import { useMutation } from "@apollo/client";
import { RESPOND_TICKET } from "graphql/queries/ticket";
import { toast } from "react-toastify";
import { ticketResponseParser } from "utils/ticketParser";
import { appStrings } from "data/app/appStrings";
import { useParams } from "react-router";
import { IMPERSONATE } from "graphql/queries/auth";

export interface ResponseScreenProps {
  userID: number;
  addResponseToTicket: (response: ITicketResponse) => void;
}

const ResponseScreen: React.FC<ResponseScreenProps> = ({
  userID,
  addResponseToTicket,
}) => {
  const [body, setBody] = useState<EditorValue>(
    RichTextEditor.createEmptyValue()
  );
  const [modalFlags, setModalFlags] = useState<Record<string, boolean>>({
    response: false,
    phoneResponse: false,
    impersonate: false,
  });

  const { id } = useParams<any>();

  const closeModal = () => {
    setModalFlags({
      response: false,
      phoneResponse: false,
      impersonate: false,
    });
  };

  const openConfirmModal = (key: string) => {
    setModalFlags({ ...modalFlags, [key]: true });
  };

  const handleBodyChange = (es: EditorValue) => setBody(es);

  const [respondTicket, { loading: respondLoading }] = useMutation(
    RESPOND_TICKET,
    {
      notifyOnNetworkStatusChange: true,
      onError: (error) => toast.error(error.message),
      onCompleted: (data) => {
        const serverResponse = data.SUPPORT_respond_ticket;
        const parsedResponse = ticketResponseParser(serverResponse);
        addResponseToTicket(parsedResponse);
        setBody(RichTextEditor.createEmptyValue());
        closeModal();
      },
    }
  );

  const onRespond = (isPhone: boolean) => {
    const response = body.toString("html");
    if (isPhone) {
      respondTicket({
        variables: {
          input: {
            ticket_id: Number(id),
            body: `<p>${appStrings.phoneResponseDefaultText}</p>`,
          },
        },
      });
      return;
    }
    if (response === "<p><br></p>") {
      toast.error(appStrings.emptyResponseError);
      return;
    }
    respondTicket({
      variables: {
        input: {
          ticket_id: Number(id),
          body: response,
        },
      },
    });
  };

  const [impersonate, { loading: impersonateLoading }] = useMutation(
    IMPERSONATE,
    {
      notifyOnNetworkStatusChange: true,
      onError: (error) => toast.error(error.message),
      onCompleted: (data) => {
        if (data.AUTH_impersonate) {
          window.location.replace(
            `${process.env.REACT_APP_DREAMLAB_URL}?from=${encodeURIComponent(
              window.location.href
            )}`
          );
        }
      },
    }
  );

  const onImpersonate = () => {
    impersonate({
      variables: {
        user_id: userID,
      },
    });
  };

  return (
    <ResponseForm
      body={body}
      openFlags={modalFlags}
      loading={respondLoading || impersonateLoading}
      onOpenConfirmation={openConfirmModal}
      onCloseModals={closeModal}
      handleBodyChange={handleBodyChange}
      respondTicket={onRespond}
      impersonateToUser={onImpersonate}
    />
  );
};

export default ResponseScreen;
