import { IMenuItem } from "models/MenuItem";
import { cloneElement } from "react";
import { FaCaretLeft } from "react-icons/fa";
import { NavLink } from "react-router-dom";

export interface MenuItemProps extends IMenuItem {}

const MenuItem: React.FC<MenuItemProps> = ({
  title,
  url,
  isExact = false,
  icon = <FaCaretLeft />,
}) => {
  const clonedIcon = cloneElement(icon, { className: "inline-block ml-2" });

  return (
    <NavLink
      to={url}
      exact={isExact}
      className="text-white bg-white bg-opacity-10 py-2 px-3 rounded-xl text-center transition duration-300 hover:bg-opacity-25"
      activeClassName="bg-opacity-30"
    >
      {clonedIcon}
      <span>{title}</span>
    </NavLink>
  );
};

export default MenuItem;
