import { useLazyQuery, useMutation } from "@apollo/client";
import PageLoader from "components/app/utils/PageLoader";
import UserDetail from "components/user";
import { IMPERSONATE } from "graphql/queries/auth";
import { GET_SINGLE_USER } from "graphql/queries/user";
import { IDreamlabData } from "models/DreamlabData";
import { IUser } from "models/User";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import { dreamlabDataParser } from "utils/dreamlabDataParser";
import { getQueryStringValue } from "utils/queryString";
import { parseStoreData } from "utils/storeParser";
import { userObjectParser } from "utils/userParser";

const UserScreen: React.FC = () => {
  const [user, setUser] = useState<IUser | undefined>(undefined);
  const [dreamlabData, setDreamlabData] = useState<IDreamlabData>({});
  const [impersonateOpen, setImpersonateOpen] = useState<boolean>(false);
  const { id } = useParams<any>();

  const infantID = Number(getQueryStringValue("infant"));

  const [getUser, { loading, error }] = useLazyQuery(
    GET_SINGLE_USER(!!infantID),
    {
      fetchPolicy: "network-only",
      onCompleted: (data) => {
        const parsedUser = userObjectParser(
          data.DREAMLAB_user.dreamlab_licenses[0]
        );
        const parsedStoreData = parseStoreData(data);
        const licenseObject = data.DREAMLAB_user.dreamlab_licenses.find(
          (item: any) => Number(item?.infant?.id) === infantID
        );
        const parsedDreamlabData = dreamlabDataParser(
          licenseObject,
          parsedStoreData
        );
        setUser(parsedUser);
        setDreamlabData(parsedDreamlabData);
      },
    }
  );

  useEffect(() => {
    const keys = ["601", "603", "108", "appState", "weight"];
    getUser({
      variables: {
        infant_id: infantID,
        id: Number(id),
        keys,
      },
    });
  }, [getUser, id, infantID]);

  const requestImpersonate = () => {
    setImpersonateOpen(true);
  };

  const closeModals = () => {
    setImpersonateOpen(false);
  };

  const [impersonate, { loading: impersonateLoading }] = useMutation(
    IMPERSONATE,
    {
      notifyOnNetworkStatusChange: true,
      onError: (error) => toast.error(error.message),
      onCompleted: (data) => {
        if (data.AUTH_impersonate) {
          window.location.replace(
            `${process.env.REACT_APP_DREAMLAB_URL}?from=${encodeURIComponent(
              window.location.href
            )}`
          );
        }
      },
    }
  );

  const onImpersonate = () => {
    impersonate({
      variables: {
        user_id: Number(id),
      },
    });
  };

  return (
    <PageLoader loading={loading} error={error}>
      {user && (
        <UserDetail
          dreamlabData={dreamlabData}
          userData={user}
          impersonateOpen={impersonateOpen}
          loading={impersonateLoading}
          closeModals={closeModals}
          impersonateToUser={onImpersonate}
          requestImpersonate={requestImpersonate}
        />
      )}
    </PageLoader>
  );
};

export default UserScreen;
