export const brand = "قنداق";

export const appStrings = {
  defaultUserName: "کاربر گرامی",
  profile: "پروفایل کاربری",
  sanctumError:
    "خطا در ارتباط با سرور. لطفا وضعیت اتصال به اینترنت خود را بررسی نمایید.",
  userPersianName: "نام",
  phone: "تلفن",
  mobile: "موبایل",
  id: "#",
  ticketTitle: "عنوان سوال",
  ticketAnswerStatus: "پاسخ",
  ticketStatus: "وضعیت",
  detail: "جزئیات",
  actions: "عملیات",
  open: "باز",
  closed: "بسته",
  loadMore: "مشاهده بیشتر",
  infantName: "نام بچه",
  age: "سن",
  weight: "وزن",
  monthsOld: "ماهه",
  kilogeram: "کیلوگرم",
  usedDays: "تعداد روزهای استفاده از سامانه",
  feedingType: "نوع شیردهی",
  motherFeeding: "شیر مادر",
  bottleFeeding: "شیر خشک",
  both: "هر دو",
  hasFeeding: "شیردهی شبانه",
  feedingCount: "تعداد شیردهی",
  selectedMethod: "روش انتخابی",
  stay: "روش ماندن",
  visit: "روش ملاقات",
  touch: "روش لمسی",
  responses: "پاسخ ها",
  yourResponse: "پاسخ شما",
  accept: "تایید",
  cancel: "انصراف",
  submitResponse: "ثبت پاسخ",
  submitPhoneResponse: "ثبت پاسخ تلفنی",
  impersonate: "ورود به حساب کاربر",
  closeTicket: "بستن تیکت",
  closeTicketAlert: {
    title: "بستن تیکت",
    description: "آیا از بستن این تیکت مطمئن هستید؟",
  },
  responseTicketAlert: {
    title: "ثبت پاسخ",
    description: "آیا از ثبت پاسخ مطمئن هستید؟",
  },
  phoneResponseTicketAlert: {
    title: "ثبت پاسخ تلفنی",
    description:
      "در صورتی که از طریق تماس تلفنی پاسخ این تیکت را داده اید و نیازی به نوشتن پاسخ دیگری نیست بر روی تایید کلیک کنید.",
  },
  impersonateAlert: {
    title: "ورود به حساب کاربر",
    description:
      "در صورت تایید شما به حساب کاربر مورد نظر انتقال داده می شوید.",
  },
  emptyResponseError: "لطفا پاسخ را تکمیل نمایید.",
  phoneResponseDefaultText:
    "پاسخ این سوال، از طریق تماس تلفنی داده شد. با تشکر از اعتماد شما به مدرسه خواب!",
  registerDate: "عضویت",
};
