import AppWrapper from "components/layout/AppWrapper";
import Apollo from "graphql/Apollo";
import Navigator from "navigation/Navigator";
import { BrowserRouter } from "react-router-dom";
import { RecoilRoot } from "recoil";
import UserIdentifier from "screens/auth/UserIdentifier";
import Modal from "react-modal";
import { ToastContainer } from "react-toastify";

Modal.setAppElement("#root");

function App() {
  return (
    <RecoilRoot>
      <BrowserRouter>
        <Apollo>
          <UserIdentifier>
            <AppWrapper>
              <Navigator />
            </AppWrapper>
          </UserIdentifier>
        </Apollo>
      </BrowserRouter>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </RecoilRoot>
  );
}

export default App;
