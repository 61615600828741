import { useLazyQuery } from "@apollo/client";
import PageLoader from "components/app/utils/PageLoader";
import UserList from "components/users";
import { GET_SLEEPSCHOOL_USERS } from "graphql/queries/user";
import { IUser } from "models/User";
import { useEffect, useState } from "react";
import { userArrayParser } from "utils/userParser";

const SleepSchoolUsersScreen: React.FC = () => {
  const [users, setUsers] = useState<IUser[]>([]);
  const [currPage, setCurrPage] = useState(1);
  const [lastPage, setLastPage] = useState(0);

  const [getUsers, { loading, error }] = useLazyQuery(GET_SLEEPSCHOOL_USERS, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      const serverUsers = data.DREAMLAB_licenses.data;
      const pageMeta = data.DREAMLAB_licenses.paginator_meta;
      const parsedUsers = userArrayParser(serverUsers);
      setUsers([...users, ...parsedUsers]);
      setLastPage(pageMeta.last_page);
    },
  });

  useEffect(() => {
    getUsers({
      variables: {
        page: currPage,
      },
    });
  }, [getUsers, currPage]);

  const loadMore = () => {
    setCurrPage(currPage + 1);
  };

  return (
    <PageLoader loading={loading} error={error}>
      <UserList
        users={users}
        hasMore={lastPage !== currPage}
        loadMore={loadMore}
      />
    </PageLoader>
  );
};

export default SleepSchoolUsersScreen;
