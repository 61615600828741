import { ReactNode, useCallback, useEffect, useState } from "react";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
} from "@apollo/client";
import { base, sanctum } from "utils/apis";
import axios from "axios";
import { useRecoilState } from "recoil";
import { tokenState } from "recoil/atoms/auth";
import { appStrings } from "data/app/appStrings";
import PageLoader from "components/app/utils/PageLoader";

const myAxios = axios.create({
  baseURL: base,
  timeout: 10 * 1000,
  headers: { Accept: "application/json" },
});

myAxios.defaults.withCredentials = true;

export interface ApolloProps {
  children: ReactNode;
}

/*
TODO: Catch axios tries
*/

const Apollo: React.FC<ApolloProps> = ({ children }) => {
  const [token, setToken] = useRecoilState(tokenState);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | undefined>(undefined);

  const getToken = useCallback(() => {
    setError(undefined);
    myAxios
      .get(sanctum)
      .then((res) => {
        if (res.config.headers["X-XSRF-TOKEN"] === undefined) getToken();
        setToken(res.config.headers["X-XSRF-TOKEN"]);
      })
      .catch(() => {
        setError(appStrings.sanctumError);
      })
      .finally(() => setLoading(false));
  }, [setToken]);

  useEffect(() => {
    getToken();
  }, [getToken]);

  const link = createHttpLink({
    uri: `${process.env.REACT_APP_BASE_URL}/graphql`,
    credentials: "include",
    headers: {
      "X-XSRF-TOKEN": token,
    },
  });

  const client = new ApolloClient({
    link: link,
    cache: new InMemoryCache(),
  });

  return (
    <PageLoader loading={loading} error={error}>
      <ApolloProvider client={client}>{children}</ApolloProvider>
    </PageLoader>
  );
};

export default Apollo;
