import { useLazyQuery } from "@apollo/client";
import Loading from "components/app/utils/Loading";
import PageLoader from "components/app/utils/PageLoader";
import { ME } from "graphql/queries/auth";
import { IUser } from "models/User";
import { ReactNode, useEffect } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { authState, tokenState } from "recoil/atoms/auth";
import { userState } from "recoil/atoms/user";

export interface UserIdentifierProps {
  children: ReactNode;
}

const UserIdentifier: React.FC<UserIdentifierProps> = ({ children }) => {
  const setUser = useSetRecoilState(userState);
  const [auth, setAuth] = useRecoilState(authState);
  const token = useRecoilValue(tokenState);

  const [getMe, { loading, error }] = useLazyQuery(ME, {
    onError: () => setAuth(false),
    onCompleted: (data) => {
      if (data.me) {
        const { first_name, last_name } = data.me;
        const user: IUser = {
          firstName: first_name,
          lastName: last_name,
        };
        setUser(user);
        setAuth(true);
      } else {
        setAuth(false);
      }
    },
  });
  useEffect(() => {
    if (token) {
      getMe();
    }
  }, [getMe, token]);

  return (
    <PageLoader loading={loading} error={error} showError={false}>
      {auth === undefined ? <Loading /> : children}
    </PageLoader>
  );
};

export default UserIdentifier;
