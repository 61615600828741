import DreamlabInfo from "components/ticket/DreamlabInfo";
import InfantInfo from "components/ticket/InfantInfo";
import ConfirmationModal from "components/utils/ConfirmationModal";
import { appStrings } from "data/app/appStrings";
import { IDreamlabData } from "models/DreamlabData";
import { IUser } from "models/User";
import UserInfo from "./UserInfo";

export interface UserDetailProps {
  userData: IUser;
  dreamlabData: IDreamlabData;
  impersonateOpen: boolean;
  loading: boolean;
  requestImpersonate: () => void;
  impersonateToUser: () => void;
  closeModals: () => void;
}

const UserDetail: React.FC<UserDetailProps> = ({
  dreamlabData,
  userData,
  loading,
  impersonateOpen,
  closeModals,
  requestImpersonate,
  impersonateToUser,
}) => {
  return (
    <div>
      <div className="grid grid-cols-2 gap-6">
        <UserInfo {...userData} impersonate={requestImpersonate} />
        <InfantInfo
          name={dreamlabData.infant?.name}
          usedFrom={dreamlabData.infant?.usedFrom}
          weight={dreamlabData.infant?.weight}
          age={dreamlabData.infant?.age}
        />
        <DreamlabInfo
          count={dreamlabData.feeding?.count}
          selectedMethod={dreamlabData.method}
          type={dreamlabData.feeding?.type}
          has={dreamlabData.feeding?.has}
        />
      </div>
      <ConfirmationModal
        open={impersonateOpen}
        loading={loading}
        bodyTexts={appStrings.impersonateAlert}
        onConfirm={() => impersonateToUser()}
        onClose={closeModals}
      />
    </div>
  );
};

export default UserDetail;
