import Pane from "components/layout/Pane";
import { toPersian } from "utils/raqam";
import { FaRegQuestionCircle } from "react-icons/fa";
import { parseDateTime } from "utils/dateParser";

export interface TicketInfoProps {
  name: string;
  mobile: string;
  title: string;
  body: string;
  date: string;
}

const TicketInfo: React.FC<TicketInfoProps> = ({
  name,
  mobile,
  title,
  body,
  date,
}) => {
  return (
    <Pane>
      <div className="flex justify-between pb-3 border-b border-gray-200 items-center">
        <span className="text-gray-700 font-semibold">
          <FaRegQuestionCircle className="inline-block text-yellow-700 ml-2 text-3xl" />
          {toPersian(name)}
        </span>
        <div>
          <span className="text-pink-700">{toPersian(mobile)}</span>
          <span className="text-gray-600 text-sm mr-3 inline-block">
            {toPersian(parseDateTime(date))}
          </span>
        </div>
      </div>
      <div className="pt-3 text-justify">
        <p className="font-semibold">{toPersian(title)}</p>
        <p className="mt-3 text-sm">{toPersian(body)}</p>
      </div>
    </Pane>
  );
};

export default TicketInfo;
