import Pane from "components/layout/Pane";
import Button from "components/utils/Button";
import ConfirmationModal from "components/utils/ConfirmationModal";
import { appStrings } from "data/app/appStrings";
import { Fragment } from "react";
import { FaPencilAlt, FaPhone, FaReply, FaSignInAlt } from "react-icons/fa";
import RichTextEditor, { EditorValue } from "react-rte";

export interface ResponseFormProps {
  body: EditorValue;
  openFlags: Record<string, boolean>;
  loading: boolean;
  onOpenConfirmation: (key: string) => void;
  onCloseModals: () => void;
  handleBodyChange: (es: EditorValue) => void;
  respondTicket: (isPhone: boolean) => void;
  impersonateToUser: () => void;
}

const ResponseForm: React.FC<ResponseFormProps> = ({
  body,
  openFlags,
  loading,
  onOpenConfirmation,
  onCloseModals,
  handleBodyChange,
  respondTicket,
  impersonateToUser,
}) => {
  return (
    <Fragment>
      <Pane>
        <div className="pb-3 mb-3 border-b border-gray-200">
          <p className="text-gray-700 font-semibold">
            <FaPencilAlt className="inline-block ml-2 text-2xl text-green-500 font-semibold" />
            {appStrings.yourResponse}
          </p>
        </div>
        <RichTextEditor
          value={body}
          onChange={handleBodyChange}
          className="font-iran-sans"
          toolbarClassName="font-iran-sans"
          autoFocus
          toolbarConfig={{
            display: [
              "INLINE_STYLE_BUTTONS",
              "BLOCK_TYPE_BUTTONS",
              "BLOCK_TYPE_DROPDOWN",
            ],
            INLINE_STYLE_BUTTONS: [
              { label: "Bold", style: "BOLD", className: "custom-css-class" },
              { label: "Italic", style: "ITALIC" },
              { label: "Underline", style: "UNDERLINE" },
            ],
            BLOCK_TYPE_DROPDOWN: [
              { label: "معمولی", style: "unstyled" },
              { label: "بزرگ", style: "header-one" },
              { label: "متوسط", style: "header-two" },
              { label: "کوچک", style: "header-three" },
            ],
            BLOCK_TYPE_BUTTONS: [
              { label: "UL", style: "unordered-list-item" },
              { label: "OL", style: "ordered-list-item" },
            ],
          }}
        />
      </Pane>
      <Pane>
        <div className="flex justify-end">
          <Button
            label={appStrings.submitResponse}
            icon={<FaReply />}
            onClick={() => onOpenConfirmation("response")}
            className="bg-green-600 text-white"
          />
          <Button
            label={appStrings.submitPhoneResponse}
            icon={<FaPhone />}
            onClick={() => onOpenConfirmation("phoneResponse")}
            className="bg-blue-600 text-white mx-3"
          />
          <Button
            label={appStrings.impersonate}
            icon={<FaSignInAlt />}
            onClick={() => onOpenConfirmation("impersonate")}
            className="bg-pink-600 text-white"
          />
        </div>
      </Pane>
      <ConfirmationModal
        open={openFlags.response}
        loading={loading}
        bodyTexts={appStrings.responseTicketAlert}
        onConfirm={() => respondTicket(false)}
        onClose={onCloseModals}
      />
      <ConfirmationModal
        open={openFlags.phoneResponse}
        loading={loading}
        bodyTexts={appStrings.phoneResponseTicketAlert}
        onConfirm={() => respondTicket(true)}
        onClose={onCloseModals}
      />
      <ConfirmationModal
        open={openFlags.impersonate}
        loading={loading}
        bodyTexts={appStrings.impersonateAlert}
        onConfirm={() => impersonateToUser()}
        onClose={onCloseModals}
      />
    </Fragment>
  );
};

export default ResponseForm;
