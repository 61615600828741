import gql from "graphql-tag";

export const GET_SLEEPSCHOOL_USERS = gql`
  query DREAMLAB_licenses($page: Int!) {
    DREAMLAB_licenses(page: $page) {
      paginator_meta {
        current_page
        last_page
      }
      data {
        infant {
          id
          name
        }
        user {
          id
          first_name
          last_name
          mobile
          created_at
        }
      }
    }
  }
`;

export const GET_SINGLE_USER = (hasInfant: boolean) => gql`
  query DREAMLAB_user($id: ID!${
    hasInfant ? `, $keys: [String]!, $infant_id: Int!` : ""
  }) {
    ${
      hasInfant
        ? `DREAMLAB_get_infant_data(keys: $keys, infant_id: $infant_id) {
      key
      value
    }`
        : ""
    }
    DREAMLAB_user(id: $id) {
        dreamlab_licenses {
           infant {
          id
          name
          adjusted_age
          dreamlab_used_from
        }
        user {
          id
          first_name
          last_name
          mobile
          created_at
        }
        }
    }
  }
`;
