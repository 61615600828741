import Pane from "components/layout/Pane";
import { TD } from "components/utils/TableUtils";
import { appStrings } from "data/app/appStrings";
import { feedingOptions } from "data/app/feedingOptions";
import { methodOptions } from "data/app/methodOptions";
import { IDreamlabFeeding } from "models/DreamlabData";
import { toPersian } from "utils/raqam";
import { FaCheckCircle, FaMinusCircle } from "react-icons/fa";

export interface DreamlabInfoProps extends IDreamlabFeeding {
  selectedMethod?: string;
}

const DreamlabInfo: React.FC<DreamlabInfoProps> = ({
  has,
  type = "",
  count,
  selectedMethod = "",
}) => {
  return (
    <Pane>
      <table className="w-full">
        <tbody>
          <tr>
            <TD>{appStrings.hasFeeding}</TD>
            <TD>
              {has ? (
                <FaCheckCircle className="inline-block text-green-600" />
              ) : (
                <FaMinusCircle className="inline-block text-red-600" />
              )}
            </TD>
          </tr>
          <tr className="bg-blue-100">
            <TD>{appStrings.feedingType}</TD>
            <TD>{feedingOptions[type]}</TD>
          </tr>
          <tr>
            <TD>{appStrings.feedingCount}</TD>
            <TD>{toPersian(count || 0)}</TD>
          </tr>
          <tr className="bg-blue-100">
            <TD>{appStrings.selectedMethod}</TD>
            <TD>{methodOptions[selectedMethod]}</TD>
          </tr>
        </tbody>
      </table>
    </Pane>
  );
};

export default DreamlabInfo;
