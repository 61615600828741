import { IUser } from "models/User";

export const userObjectParser = (serverObject: any) => {
  const infantObj = serverObject.infant;
  const userObj = serverObject.user;
  const parsedUser: IUser = {
    id: userObj?.id,
    firstName: userObj?.first_name,
    lastName: userObj?.last_name,
    phone: userObj?.mobile,
    registerDate: userObj?.created_at,
    infantID: infantObj?.id,
    infantName: infantObj?.name,
  };
  return parsedUser;
};

export const userArrayParser = (serverArray: any[]) => {
  const parsedArray = serverArray.map((item) => userObjectParser(item));
  return parsedArray;
};
